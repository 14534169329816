@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap');

html, body, *{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Jost', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

.container {
  max-width: 1240px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 15px;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
}
.container > div{
  padding: 0 10px;
}

#logo{
  width: 50%;
  height: auto;
}

::placeholder{
  color: #000;
  opacity: 0.5;
}
.form-roleta{
  text-align: center;
}
.form-roleta .form-group{
  margin-bottom: 12px;
  padding: 0px 15px;
}
.form-roleta .form-group label{
  display: inline-block;
  width: 100%;
  text-align-last: left;
  font-weight: 600;
  color: #2b2935;
}
.form-roleta .form-group input:not([type=checkbox]), .form-roleta .form-group select{
  height: 40px;
  width: 100%;
  padding: 0 10px;
  font-size: 16px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 20px;
  border: 1px #540B3840 solid;
  outline: none;
  background: #f2f2f2cc;
  color: #000;
}
.form-roleta .form-group input:not([type=checkbox]):focus, .form-roleta .form-group select:focus{
  border: 1px #444 solid;
}
.form-roleta button{
  height: 40px;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 700;
  background: #002F8B;
  color: #F2F2F2;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  margin-bottom: 25px;
  text-transform: uppercase;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.form-roleta button:hover{
  background: #002F8Bcc;
  color: #fff;
}
.form-roleta button[disabled]{
  cursor: not-allowed;
}

input:disabled, select:disabled{
  background-color: #e2e2e2;
}

h2{
  margin-top: 0;
  margin-bottom: 0;
  color: #020202;
  font-weight: 600;
  font-size: 37px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0 15px;
  text-align: center;
  line-height: 42px;
}
p{
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 12px;
  color: #020202;
  padding: 0 15px;
  text-align: center;
  font-weight: bold;
}

.wheel{
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  width: 350px;
  height: 350px;
  position: relative;
  -webkit-box-shadow: 2px 1px 6px 0 rgba(0,0,0,0.3);
  box-shadow: 2px 1px 6px 0 rgba(0,0,0,0.3);
  overflow: hidden;
  display: inline-block;
  margin: 0;
}
.wheel .center{
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 135px;
  left: 135px;
  -webkit-box-shadow: 2px 1px 6px 0 rgba(0,0,0,0.3);
  box-shadow: 2px 1px 6px 0 rgba(0,0,0,0.3);
  background: #002F8B;
  position: relative;
  z-index: 200;
  margin: 0;
  display: inline-block;
}
.wheel .faixa{
  position: absolute;
  left: 175px;
  top: 175px;
  width: 175px;
  height: 175px;
  background: #D92121;
  color: #f2f2f2;
  text-align: center;
  z-index: 10;
  transform-origin: top left;
  top: 175;
  left: 175px;
}
.wheel .faixa:nth-child(3){
  background: #f2f2f2;
  transform: rotate(45deg);
  color: #000;
  z-index: 20;
}
.wheel .faixa:nth-child(4){
  color: #f2f2f2;
  transform: rotate(90deg);
  z-index: 30;
}
.wheel .faixa:nth-child(5){
  background: #f2f2f2;
  transform: rotate(135deg);
  color: #000;
  z-index: 40;
}
.wheel .faixa:nth-child(6){
  transform: rotate(180deg);
  z-index: 50;
}
.wheel .faixa:nth-child(7){
  background: #f2f2f2;
  color: #000;
  transform: rotate(225deg);
  z-index: 50;
}
.wheel .faixa:nth-child(8){
  transform: rotate(270deg);
  z-index: 50;
}

.wheel .faixa:nth-child(9){
  background: #f2f2f2;
  color: #000;
  background: none;
  transform: rotate(270deg);
  z-index: 70;
  overflow: hidden;
}
.wheel .faixa:nth-child(9) > div{
  position: absolute;
  background: #f2f2f2;
  color: #000;
  width: 175px;
  height: 175px;
  transform: rotate(45deg);
  top: 0px;
  left: 0px;
  transform-origin: top left;
}

.wheel .faixa span{
  position: absolute;
  top: 6px;
  left: 32px;
  transform: rotate(24deg);
  font-size: 14px;
  line-height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 67px;
}
#wheel-wrapper{
  position: relative;
  overflow: hidden;
  display: inline-block;
  float: right;
  padding-right: 8px;
}
#wheel-wrapper #seta{
  position: absolute;
  left: 340px;
  top: 160px;
  z-index: 1000;
  width: 0px;
  height: 0px;

  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent; 
  border-right: 15px solid #FF7E2A; 
}

@media only screen and (max-width: 750px){
  .container{
    grid-template-columns: 1fr;
    min-height: auto;
  }
  .container > div:first-child{
    order: 2;
  }
  .container > div:last-child{
    order: 1;
  }
}
